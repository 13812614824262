<template>
  <div class="app-layout">
    <div class="waiting-bar" :style="progressStyle">
      <div
        class="waiting-bar__progress"
        :style="`width:${progressWidth}%`"
      ></div>
    </div>
    <template v-if="draw">
      <template v-if="device !== 'desktop' && windowWidth <= 768">
        <OverlayMenu
          @toggleOverlayMenu="toggleOverlayMenu"
          :classToDisplay="getMobileOverlyClass()"
        />
      </template>

      <Menu :windowWidth="windowWidth" :device="device" />
      <div class="app-main" :class="'app-main--' + device">
        <template v-if="online">
          <Header
            :name="componentName"
            :headerColor="headerColor"
            :device="device"
            :windowWidth="windowWidth"
            @toggleOverlayMenu="toggleOverlayMenu"
          />
          <div class="container-fluid px-0 app-main__content">
            <router-view> </router-view>

            <FloatingAction />
          </div>
        </template>
        <template v-else>
          <offline />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import {
  GET_WINDOW_WIDTH,
  GET_DEVICE_TYPE
} from "@/core/services/store/media-queries.module";
import { LOGOUT, ADD_PUSH_TOKEN } from "@/core/services/store/auth.module";
import Header from "@/view/layout/header/Header";
import Menu from "@/view/layout/Menu";
import OverlayMenu from "@/view/layout/OverlayMenu";
import FloatingAction from "@/view/components/common/FloatingAction";
import Offline from "@/view/components/Offline";

import {
  GET_ORDER_STATUS_ACTIVE,
  GET_ORDER_STATUS_DELIVERED,
  GET_ORDER_STATUS_PENDING
  // FIND_RIDER
} from "@/core/services/store/orders.module";

import { GET_REGION } from "@/core/services/store/region.module";

// const bc = new BroadcastChannel("reset_timer");
export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}base-layout`,
  components: {
    Menu,
    Header,
    OverlayMenu,
    FloatingAction,
    Offline
  },
  data() {
    return {
      componentName: "Order Status",
      headerColor: "red",
      overlayMenu: false,
      draw: false,
      riderWorker: null,
      progressWidth: 0,
      progressStyle:
        "position: absolute;top: 0;left: 0;width: 100%;z-index: 1;margin: 0;",
      status: "",
      online: false
    };
  },
  created() {
    window.addEventListener("resize", () => {
      this.$store.dispatch(GET_WINDOW_WIDTH);
      this.$store.dispatch(GET_DEVICE_TYPE);
    });
    this.$store.dispatch(GET_DEVICE_TYPE);
    this.$detector.reactor.addEventListener("app-connect-change", e => {
      console.log("file: Layout.vue | line 97 | created | e", e);
      this.online = e;
    });
  },
  beforeMount() {
    this.online = this.$detector.getState();
  },
  async mounted() {
    await this.$store.dispatch(GET_REGION);
    const x = this.isAuthenticated();
    this.componentName = this.$route.name;
    if (!x) this.$router.push({ name: "Login" });
    else {
      this.draw = true;
      this.$store.dispatch(GET_WINDOW_WIDTH);
      const start = new Date().valueOf();
      this.startEvent(start, start + 15000);
      this.$firebase.initMessaging();
      const permission = await this.$firebase.getPushPermissions();
      if (permission) {
        const generated = await this.$firebase.getPushToken();
        if (generated) {
          const token = this.$firebase.deviceToken;
          await this.$store.dispatch(ADD_PUSH_TOKEN, token);
        }
      }
    }
  },
  metaInfo() {
    return {
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_API_KEY}&libraries=places`,
          async: true,
          defer: true
        }
      ]
    };
  },
  methods: {
    ...mapGetters([
      "getWindowWidth",
      "getDeviceType",
      "isAuthenticated",
      "getNonRiderOrders"
    ]),
    ...mapActions({
      activeOrders: GET_ORDER_STATUS_ACTIVE,
      deliveredOrders: GET_ORDER_STATUS_DELIVERED,
      pendingOrders: GET_ORDER_STATUS_PENDING
    }),
    startEvent(start, end) {
      const int = setInterval(() => {
        // calculate progressWidth periodically
        const now = new Date().valueOf();
        if (now >= end) {
          // if finished
          this.progressWidth = 100;
          clearInterval(int);
          return;
        }
        this.progressWidth = ((now - start) / (end - start)) * 100;
      }, 10);
    },

    async findOrders() {
      if (this.online) {
        await this.activeOrders();
        await this.pendingOrders();
        await this.deliveredOrders();
      }

      const start = new Date().valueOf();
      this.startEvent(start, start + 15000);
    },
    async logout() {
      await this.$store.dispatch(LOGOUT);
    },
    toggleOverlayMenu() {
      this.overlayMenu = !this.overlayMenu;
    },
    getMobileOverlyClass() {
      if (this.device !== "desktop" && this.windowWidth <= 768) {
        return this.overlayMenu
          ? "overlay-menu--active"
          : "overlay-menu--inactive";
      }
      return "";
    }
  },
  computed: {
    ...mapState({
      windowWidth: state => state.appWindow.windowWidth,
      device: state => {
        return state.appWindow.device;
      }
    })
  },
  watch: {
    $route(to) {
      this.componentName = to.name;
      this.headerColor = to.meta.color;
    },
    progressWidth(val) {
      if (val === 100) {
        this.findOrders();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.app-layout {
  display: flex;
}
</style>
