<template>
  <div class="floating-action" v-if="message !== ''">
    <div class="floating-action__content">
      {{ this.message }}
    </div>
  </div>
</template>

<script>
import DateService from "@/core/services/date.service";

export default {
  data() {
    return {
      message: DateService.canShowMessage()
    };
  }
};
</script>

<style lang="sass" scoped></style>
