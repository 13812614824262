<template>
  <div class="app-main__header" :class="'app-main__header--' + headerColor">
    <template v-if="device !== 'desktop' && windowWidth <= 768">
      <div
        @click="handleProfileImageClick"
        class="app-main__header__image"
        :class="'app-main__header__image--' + headerColor"
      >
        <img
          :src="
            avatar !== false
              ? avatar
              : '@/assets/static-assets/defaults/user.jpg'
          "
          alt="Default User"
        />
      </div>
    </template>
    <span class="app-main__header__text">
      {{ xname === "" ? name : xname }}
    </span>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapState } from "vuex";
import { getUserAvatar } from "@/core/services/jwt.service";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}header`,

  props: {
    device: String,
    name: String,
    headerColor: String,
    windowWidth: Number
  },
  mounted() {
    this.getName();
  },
  data() {
    return { xname: "", avatar: getUserAvatar() };
  },

  methods: {
    handleProfileImageClick() {
      this.$emit("toggleOverlayMenu");
    },
    getName() {
      const to = this.$route;
      if (to.path.includes("/order/")) {
        const res =
          this.$store.getters.getPendingOrder(this.$route.params.id) ||
          this.$store.getters.getActiveOrder(this.$route.params.id) ||
          this.$store.getters.getDeliveredOrder(this.$route.params.id);
        if (res) {
          if (this.regionCheckUSorOther) {
            this.xname = `${res.customer.name.split(" ")}'s order`;
          } else {
            this.xname = `${res.receiver.name.split(" ")}'s order`;
          }
          // this.xname = "static Name John";
        } else {
          this.xname = to.name;
        }
      } else {
        this.xname = to.name;
      }
    }
  },
  watch: {
    $route(to) {
      this.getName();
    }
  },
  computed: {
    ...mapState({
      regionCheckUSorOther: state => state.regions.region
    })
  }
};
</script>

<style lang="scss" scoped></style>
