<template>
  <div class="menu-div">
    <template v-if="device !== 'desktop' && windowWidth <= 768">
      <BottomBar />
    </template>
    <template v-else>
      <Sidebar />
    </template>
  </div>
</template>

<script>
import BottomBar from "@/view/components/mobile/BottomBar";
import Sidebar from "@/view/components/desktop/Sidebar";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}menu-wrapper`,
  props: {
    windowWidth: Number,
    device: String
  },
  components: {
    Sidebar,
    BottomBar
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.menu-div {
  display: inline-block;
}
</style>
