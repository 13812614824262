var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlay-menu",class:`${_vm.classToDisplay}`},[_c('div',{staticClass:"overlay-menu__body"},[_c('div',{staticClass:"overlay-menu__content"},[_c('b-row',{staticClass:"pb-3"},[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"overlay-menu__user"},[_c('img',{attrs:{"src":_vm.avatar !== false
                  ? _vm.avatar
                  : '@/assets/static-assets/defaults/user.jpg',"alt":"Default User"}}),_c('span',{staticClass:"overlay-menu__user__name"},[_vm._v(_vm._s(_vm.userName !== "" ? _vm.userName : "Maroo Tandoor"))])])]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"overlay-menu__ratings"},[_c('div',{staticClass:"overlay-menu__ratings__stars"},[_c('i',{staticClass:"fa fa-star gold"}),_c('i',{staticClass:"fa fa-star gold"}),_c('i',{staticClass:"fa fa-star gold"}),_c('i',{staticClass:"fa fa-star gold"}),_c('i',{staticClass:"fa fa-star"})]),_c('div',{staticClass:"overlay-menu__ratings__value"},[_vm._v("4.0")])])])],1),_c('b-row',[_c('ul',{staticClass:"ds__nav ds__nav--mobile"},[_c('li',{staticClass:"ds__nav__item"},[_c('router-link',{attrs:{"to":"/order-status/in-progress"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"ds__nav__link ds__nav__link--white",class:[
                  _vm.$route.path.includes('/order-status/') &&
                    'ds__nav__link--active'
                ],attrs:{"disabled":_vm.waiting,"href":href},on:{"click":function($event){navigate && _vm.closeOverlayMenu()}}},[_c('div',{staticClass:"ds__nav__image__container"},[_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/bottom-app-bar/order-status@3x.png"),"alt":_vm.$route.path.includes('/order-status/')
                        ? 'Order-status-Active'
                        : 'Order-status'}})]),_c('span',{staticClass:"ds__nav__text"},[_vm._v("Order Status")])])]}}])})],1),_c('li',{staticClass:"ds__nav__item"},[_c('a',{staticClass:"ds__nav__link ds__nav__link--white",attrs:{"disabled":_vm.waiting,"href":"javascript:void(0);"},on:{"click":_vm.logout}},[_c('div',{staticClass:"ds__nav__image__container"},[_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/bottom-app-bar/help@3x.png"),"alt":"Install App"}})]),_c('span',{staticClass:"ds__nav__text"},[_vm._v("Logout")])])]),_c('li',{staticClass:"ds__nav__item"},[_c('a',{staticClass:"ds__nav__link ds__nav__link--white text-center",attrs:{"disabled":"","href":"javascript:void(0);"}},[_c('span',[_vm._v("BaskytBusiness")]),_vm._v("v"+_vm._s(_vm.$store.getters.appVersion)+" ")])]),(_vm.deferredPrompt !== null)?[_c('li',{staticClass:"ds__nav__item"},[_c('a',{staticClass:"ds__nav__link ds__nav__link--white",attrs:{"disabled":_vm.waiting,"href":"javascript:void(0);"},on:{"click":_vm.installApp}},[_c('div',{staticClass:"ds__nav__image__container"},[_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/bottom-app-bar/help@3x.png"),"alt":"Install App"}})]),_c('span',{staticClass:"ds__nav__text"},[_vm._v("Install")])])])]:_vm._e()],2)])],1),_c('div',{staticClass:"overlay-menu__back-btn",on:{"click":_vm.closeOverlayMenu}},[_c('i',{staticClass:"fa fa-chevron-right"})]),_c('div',{staticClass:"overlay-menu__socials"},[_c('div',[_c('a',{attrs:{"disabled":_vm.waiting,"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-facebook"})]),_c('a',{attrs:{"disabled":_vm.waiting,"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-globe"})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }