<template>
  <div class="ds">
    <template v-if="deferredPrompt !== null">
      <a
        :disabled="waiting"
        href="javascript:void(0);"
        class="ds__nav__link ds__install-button"
        @click="installApp"
      >
        <div
          class="ds__nav__image__container ds__nav__image__container--install"
        >
          <img
            src="@/assets/static-assets/bottom-app-bar/help-silver@3x.png"
            alt="install pwa"
            class="ds__nav__image"
          />
        </div>
        <span class="ds__nav__text ds__nav__text--install">Install</span>
      </a>
    </template>

    <a
      style="pointer-events:none"
      :disabled="waiting"
      href="javascript:void(0);"
      class="ds__user"
    >
      <div class="ds__user__image">
        <img
          :src="
            avatar !== false
              ? avatar
              : '@/assets/static-assets/defaults/user.jpg'
          "
          alt="Default User"
        />
      </div>
      <span class="ds__user__text">{{
        userName !== "" ? userName : "Maroo Tandoor"
      }}</span>
    </a>
    <ul class="ds__nav">
      <li class="ds__nav__item">
        <router-link to="/orders/pending" v-slot="{ href, navigate }">
          <a
            :disabled="waiting"
            :href="href"
            @click="navigate"
            class="ds__nav__link"
            :class="[
              $route.path.includes('/orders/') && 'ds__nav__link--active'
            ]"
          >
            <div class="ds__nav__image__container">
              <img
                v-if="$route.path.includes('/orders/')"
                src="@/assets/static-assets/side bar/Path 3959@3x.png"
                :alt="
                  $route.path.includes('/orders/') ? 'Orders Active' : 'Orders'
                "
                class="ds__nav__image"
              />
              <img
                v-if="!$route.path.includes('/orders/')"
                src="@/assets/static-assets/side bar/Path 3959a@3x.png"
                :alt="
                  $route.path.includes('/orders/') ? 'Orders Active' : 'Orders'
                "
                class="ds__nav__image"
              />
            </div>
            <span class="ds__nav__text">Orders</span>
          </a>
        </router-link>
      </li>
      <!-- Order Status -->
      <li class="ds__nav__item">
        <router-link to="/order-status/in-progress" v-slot="{ href, navigate }">
          <a
            :disabled="waiting"
            :href="href"
            @click="navigate"
            class="ds__nav__link"
            :class="[
              $route.path.includes('/order-status/') && 'ds__nav__link--active'
            ]"
          >
            <div class="ds__nav__image__container">
              <img
                v-if="$route.path.includes('/order-status/')"
                src="@/assets/static-assets/side bar/ic_cached_24pxa@3x.png"
                :alt="
                  $route.path.includes('/order-status/')
                    ? 'Order-status-Active'
                    : 'Order-status'
                "
                class="ds__nav__image"
              />
              <img
                v-if="!$route.path.includes('/order-status/')"
                src="@/assets/static-assets/side bar/ic_cached_24px@3x.png"
                :alt="
                  $route.path.includes('/order-status/')
                    ? 'Order-status-Active'
                    : 'Order-status'
                "
                class="ds__nav__image"
              />
            </div>
            <span class="ds__nav__text">Order Status</span>
          </a>
        </router-link>
      </li>
      <!-- Order history -->
      <li class="ds__nav__item">
        <router-link
          to="/order-history"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :disabled="waiting"
            :href="href"
            @click="navigate"
            class="ds__nav__link"
            :class="[
              isActive && 'ds__nav__link--active',
              isExactActive && 'ds__nav__link--active'
            ]"
          >
            <div class="ds__nav__image__container">
              <img
                v-if="$route.path.includes('/order-history')"
                src="@/assets/static-assets/side bar/historya@3x.png"
                :alt="
                  isActive && isExactActive
                    ? 'Order-History-active'
                    : 'Order-History'
                "
                class="ds__nav__image"
              />
              <img
                v-if="!$route.path.includes('/order-history')"
                src="@/assets/static-assets/side bar/history@3x.png"
                :alt="
                  isActive && isExactActive
                    ? 'Order-History-active'
                    : 'Order-History'
                "
                class="ds__nav__image"
              />
            </div>
            <span class="ds__nav__text">Order History</span>
          </a>
        </router-link>
      </li>
      <!-- <li class="ds__nav__item">
        <router-link
          to="/performance"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :disabled="waiting"
            :href="href"
            @click="navigate"
            class="ds__nav__link"
            :class="[
              isActive && 'ds__nav__link--active',
              isExactActive && 'ds__nav__link--active'
            ]"
          >
            <div class="ds__nav__image__container">
              <img
                src="@/assets/static-assets/side bar/performance@3x.png"
                :alt="
                  isActive && isExactActive
                    ? 'Performance Active'
                    : 'Performance'
                "
                class="ds__nav__image"
              />
            </div>
            <span class="ds__nav__text">Performance</span>
          </a>
        </router-link>
      </li> -->

      <!-- Below is Payment  -->

      <li v-if="regionCheckUSorOther === false" class="ds__nav__item">
        <router-link to="/payments/pending" v-slot="{ href, navigate }">
          <a
            :disabled="waiting"
            :href="href"
            @click="navigate"
            class="ds__nav__link"
            :class="[
              $route.path.includes('/payments/') && 'ds__nav__link--active'
            ]"
          >
            <div class="ds__nav__image__container">
              <img
                v-if="$route.path.includes('/payments/')"
                src="@/assets/static-assets/side bar/edita@3x.png"
                :alt="
                  $route.path.includes('/payments/')
                    ? 'Payments Active'
                    : 'Payments'
                "
                class="ds__nav__image"
              />
              <img
                v-if="!$route.path.includes('/payments/')"
                src="@/assets/static-assets/side bar/edit@3x.png"
                :alt="
                  $route.path.includes('/payments/')
                    ? 'Payments Active'
                    : 'Payments'
                "
                class="ds__nav__image"
              />
            </div>
            <span class="ds__nav__text">Payments</span>
          </a>
        </router-link>
      </li>

      <!-- above is Payment  -->

      <!-- below is invoice -->
      <li v-if="regionCheckUSorOther === false" class="ds__nav__item">
        <router-link to="/invoices/allinvoice" v-slot="{ href, navigate }">
          <a
            :disabled="waiting"
            :href="href"
            @click="navigate"
            class="ds__nav__link"
            :class="[
              $route.path.includes('/invoices/') && 'ds__nav__link--active'
            ]"
          >
            <div class="ds__nav__image__container">
              <img
                v-if="$route.path.includes('/invoices/')"
                src="@/assets/static-assets/side bar/Path 396a9@3x.png"
                :alt="
                  $route.path.includes('/invoices/')
                    ? 'Invoices Active'
                    : 'Invoices'
                "
                class="ds__nav__image"
              />
              <img
                v-if="!$route.path.includes('/invoices/')"
                src="@/assets/static-assets/side bar/Path 3969@3x.png"
                :alt="
                  $route.path.includes('/invoices/')
                    ? 'Invoices Active'
                    : 'Invoices'
                "
                class="ds__nav__image"
              />
            </div>
            <span class="ds__nav__text">Invoices</span>
          </a>
        </router-link>
      </li>

      <!-- above is invoice -->

      <!-- <li class="ds__nav__item">
        <router-link
          to="/revenue"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :disabled="waiting"
            :href="href"
            @click="navigate"
            class="ds__nav__link"
            :class="[
              isActive && 'ds__nav__link--active',
              isExactActive && 'ds__nav__link--active'
            ]"
          >
            <div class="ds__nav__image__container">
              <img
                src="@/assets/static-assets/side bar/revenue@3x.png"
                :alt="isActive && isExactActive ? 'Revenue Active' : 'Revenue'"
                class="ds__nav__image"
              />
            </div>
            <span class="ds__nav__text">Revenue</span>
          </a>
        </router-link>
      </li> -->
      <!-- <li class="ds__nav__item">
        <router-link
          to="/my-profile"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :disabled="waiting"
            :href="href"
            @click="navigate"
            class="ds__nav__link"
            :class="[
              isActive && 'ds__nav__link--active',
              isExactActive && 'ds__nav__link--active'
            ]"
          >
            <div class="ds__nav__image__container">
              <img
                src="@/assets/static-assets/side bar/my-profile@3x.png"
                :alt="isActive && isExactActive ? 'Profile Active' : 'Profile'"
                class="ds__nav__image"
              />
            </div>
            <span class="ds__nav__text">My Profile</span>
          </a>
        </router-link>
      </li> -->

      <!-- Notifications Link -->
      <!-- <li class="ds__nav__item">
        <router-link
          to="/notifications"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :disabled="waiting"
            :href="href"
            @click="navigate"
            class="ds__nav__link"
            :class="[
              isActive && 'ds__nav__link--active',
              isExactActive && 'ds__nav__link--active'
            ]"
          >
            <div class="ds__nav__image__container">
              <img
                src="@/assets/static-assets/side bar/notifications-silver@3x.png"
                :alt="
                  isActive && isExactActive
                    ? 'Notifications Active'
                    : 'Notifications'
                "
                class="ds__nav__image"
              />
            </div>
            <span class="ds__nav__text">Notifications</span>
          </a>
        </router-link>
      </li> -->
      <!-- Referrals Link -->
      <!--  <li class="ds__nav__item">
        <router-link
          to="/referrals"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :disabled="waiting"
            :href="href"
            @click="navigate"
            class="ds__nav__link"
            :class="[
              isActive && 'ds__nav__link--active',
              isExactActive && 'ds__nav__link--active'
            ]"
          >
            <div class="ds__nav__image__container">
              <img
                src="@/assets/static-assets/side bar/referrals-silver@3x.png"
                :alt="
                  isActive && isExactActive ? 'Referrals Active' : 'Referrals'
                "
                class="ds__nav__image"
              />
            </div>
            <span class="ds__nav__text">Referrals</span>
          </a>
        </router-link>
      </li> -->
      <!-- Tutorial Link -->
      <!-- <li class="ds__nav__item">
        <router-link
          to="/tutorial"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :disabled="waiting"
            :href="href"
            @click="navigate"
            class="ds__nav__link"
            :class="[
              isActive && 'ds__nav__link--active',
              isExactActive && 'ds__nav__link--active'
            ]"
          >
            <div class="ds__nav__image__container">
              <img
                src="@/assets/static-assets/side bar/tutorial-silver@3x.png"
                :alt="
                  isActive && isExactActive ? 'Tutorials Active' : 'Tutorials'
                "
                class="ds__nav__image"
              />
            </div>
            <span class="ds__nav__text">Tutorial</span>
          </a>
        </router-link>
      </li> -->
      <!-- Help link -->
      <li class="ds__nav__item">
        <router-link
          to="/help"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a
            :disabled="waiting"
            :href="href"
            @click="navigate"
            class="ds__nav__link"
            :class="[
              isActive && 'ds__nav__link--active',
              isExactActive && 'ds__nav__link--active'
            ]"
          >
            <div class="ds__nav__image__container">
              <img
                v-if="$route.path.includes('/help')"
                src="@/assets/static-assets/side bar/ic_help_outaline_24px@3x.png"
                :alt="isActive && isExactActive ? 'Help Active' : 'Help'"
                class="ds__nav__image"
              />
              <img
                v-if="!$route.path.includes('/help')"
                src="@/assets/static-assets/side bar/ic_help_outline_24px@3x.png"
                :alt="isActive && isExactActive ? 'Help Active' : 'Help'"
                class="ds__nav__image"
              />
            </div>
            <span class="ds__nav__text">Help</span>
          </a>
        </router-link>
      </li>

      <li class="ds__nav__item">
        <a
          :disabled="waiting"
          href="javascript:void(0);"
          class="ds__nav__link"
          @click="logout"
        >
          <div class="ds__nav__image__container">
            <img
              src="@/assets/static-assets/side bar/XMLID_2_@3x.png"
              alt="install pwa"
              class="ds__nav__image"
            />
          </div>
          <span class="ds__nav__text">Logout</span>
        </a>
      </li>

      <li class="ds__nav__item ">
        <div class="app-version app-version--lg text-center">
          <span>BaskytBusiness</span>v{{ $store.getters.appVersion }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { GET_INSTALL_STATUS } from "@/core/services/store/general.module";
import { LOGOUT } from "@/core/services/store/auth.module";
import { getUserName, getUserAvatar } from "@/core/services/jwt.service";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}sidebar-menu`,
  data() {
    return {
      waiting: false,
      userName: getUserName(),
      avatar: getUserAvatar()
    };
  },

  methods: {
    ...mapGetters(["getInstallPrompt"]),
    installApp() {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === "accepted") {
          this.$store.dispatch(GET_INSTALL_STATUS, null);
        }
      });
    },
    async logout() {
      this.waiting = true;
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: "Login" });
      });
      this.waiting = false;
    }
  },
  computed: {
    ...mapState({
      deferredPrompt: state => state.general.installPrompt,
      regionCheckUSorOther: state => state.regions.region
    })
  }
};
</script>

<style lang="scss" scoped></style>
