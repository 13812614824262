<template>
  <div class="overlay-menu" :class="`${classToDisplay}`">
    <div class="overlay-menu__body">
      <div class="overlay-menu__content">
        <b-row class="pb-3">
          <b-col cols="6">
            <div class="overlay-menu__user">
              <img
                :src="
                  avatar !== false
                    ? avatar
                    : '@/assets/static-assets/defaults/user.jpg'
                "
                alt="Default User"
              />
              <span class="overlay-menu__user__name">{{
                userName !== "" ? userName : "Maroo Tandoor"
              }}</span>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="overlay-menu__ratings">
              <div class="overlay-menu__ratings__stars">
                <i class="fa fa-star gold"></i>
                <i class="fa fa-star gold"></i>
                <i class="fa fa-star gold"></i>
                <i class="fa fa-star gold"></i>
                <i class="fa fa-star"></i>
              </div>
              <div class="overlay-menu__ratings__value">4.0</div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <ul class="ds__nav ds__nav--mobile">
            <!-- Order Status -->
            <li class="ds__nav__item">
              <router-link
                to="/order-status/in-progress"
                v-slot="{ href, navigate }"
              >
                <a
                  :disabled="waiting"
                  :href="href"
                  @click="navigate && closeOverlayMenu()"
                  class="ds__nav__link ds__nav__link--white"
                  :class="[
                    $route.path.includes('/order-status/') &&
                      'ds__nav__link--active'
                  ]"
                >
                  <div class="ds__nav__image__container">
                    <img
                      src="@/assets/static-assets/bottom-app-bar/order-status@3x.png"
                      :alt="
                        $route.path.includes('/order-status/')
                          ? 'Order-status-Active'
                          : 'Order-status'
                      "
                      class="ds__nav__image"
                    />
                  </div>
                  <span class="ds__nav__text">Order Status</span>
                </a>
              </router-link>
            </li>
            <!-- <li v-if="region === 'PK'" class="ds__nav__item">
              <router-link
                to="/invoices/allinvoice"
                v-slot="{ href, navigate }"
              >
                <a
                  :disabled="waiting"
                  :href="href"
                  @click="navigate && closeOverlayMenu()"
                  class="ds__nav__link ds__nav__link--white"
                  :class="[
                    $route.path.includes('/invoices/') &&
                      'ds__nav__link--active'
                  ]"
                >
                  <div class="ds__nav__image__container">
                    <img
                      src="@/assets/static-assets/side bar/dollar-currency-symbol.png"
                      :alt="
                        $route.path.includes('/order-status/')
                          ? 'Invoices-Active'
                          : 'Invoices-status'
                      "
                      class="ds__nav__image"
                    />
                  </div>
                  <span class="ds__nav__text">Invoices</span>
                </a>
              </router-link>
            </li> -->
            <!-- Order history -->
            <!-- <li class="ds__nav__item">
              <router-link
                to="/order-history"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :disabled="waiting"
                  :href="href"
                  @click="navigate && closeOverlayMenu()"
                  class="ds__nav__link ds__nav__link--white"
                  :class="[
                    isActive && 'ds__nav__link--active',
                    isExactActive && 'ds__nav__link--active'
                  ]"
                >
                  <div class="ds__nav__image__container">
                    <img
                      src="@/assets/static-assets/bottom-app-bar/order-history@3x.png"
                      :alt="
                        isActive && isExactActive
                          ? 'Order-History-active'
                          : 'Order-History'
                      "
                      class="ds__nav__image"
                    />
                  </div>
                  <span class="ds__nav__text">Order History</span>
                </a>
              </router-link>
            </li> -->
            <!-- Notifications Link -->
            <!-- <li class="ds__nav__item">
              <router-link
                to="/notifications"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :disabled="waiting"
                  :href="href"
                  @click="navigate && closeOverlayMenu()"
                  class="ds__nav__link ds__nav__link--white"
                  :class="[
                    isActive && 'ds__nav__link--active',
                    isExactActive && 'ds__nav__link--active'
                  ]"
                >
                  <div class="ds__nav__image__container">
                    <img
                      src="@/assets/static-assets/bottom-app-bar/notifications@3x.png"
                      :alt="
                        isActive && isExactActive
                          ? 'Notifications Active'
                          : 'Notifications'
                      "
                      class="ds__nav__image"
                    />
                  </div>
                  <span class="ds__nav__text">Notifications</span>
                </a>
              </router-link>
            </li> -->
            <!-- Referrals Link -->
            <!-- <li class="ds__nav__item">
              <router-link
                to="/referrals"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :disabled="waiting"
                  :href="href"
                  @click="navigate && closeOverlayMenu()"
                  class="ds__nav__link ds__nav__link--white"
                  :class="[
                    isActive && 'ds__nav__link--active',
                    isExactActive && 'ds__nav__link--active'
                  ]"
                >
                  <div class="ds__nav__image__container">
                    <img
                      src="@/assets/static-assets/bottom-app-bar/referrals@3x.png"
                      :alt="
                        isActive && isExactActive
                          ? 'Referrals Active'
                          : 'Referrals'
                      "
                      class="ds__nav__image"
                    />
                  </div>
                  <span class="ds__nav__text">Referrals</span>
                </a>
              </router-link>
            </li> -->
            <!-- Tutorial Link -->
            <!-- <li class="ds__nav__item">
              <router-link
                to="/tutorial"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :disabled="waiting"
                  :href="href"
                  @click="navigate && closeOverlayMenu()"
                  class="ds__nav__link ds__nav__link--white"
                  :class="[
                    isActive && 'ds__nav__link--active',
                    isExactActive && 'ds__nav__link--active'
                  ]"
                >
                  <div class="ds__nav__image__container">
                    <img
                      src="@/assets/static-assets/bottom-app-bar/tutorial@3x.png"
                      :alt="
                        isActive && isExactActive
                          ? 'Tutorials Active'
                          : 'Tutorials'
                      "
                      class="ds__nav__image"
                    />
                  </div>
                  <span class="ds__nav__text">Tutorial</span>
                </a>
              </router-link>
            </li> -->
            <!-- Help link -->
            <!-- <li class="ds__nav__item">
              <router-link
                to="/help"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <a
                  :disabled="waiting"
                  :href="href"
                  @click="navigate && closeOverlayMenu()"
                  class="ds__nav__link ds__nav__link--white"
                  :class="[
                    isActive && 'ds__nav__link--active',
                    isExactActive && 'ds__nav__link--active'
                  ]"
                >
                  <div class="ds__nav__image__container">
                    <img
                      src="@/assets/static-assets/bottom-app-bar/help@3x.png"
                      :alt="isActive && isExactActive ? 'Help Active' : 'Help'"
                      class="ds__nav__image"
                    />
                  </div>
                  <span class="ds__nav__text">Help</span>
                </a>
              </router-link>
            </li> -->
            <li class="ds__nav__item">
              <a
                :disabled="waiting"
                href="javascript:void(0);"
                @click="logout"
                class="ds__nav__link ds__nav__link--white"
              >
                <div class="ds__nav__image__container">
                  <img
                    src="@/assets/static-assets/bottom-app-bar/help@3x.png"
                    alt="Install App"
                    class="ds__nav__image"
                  />
                </div>
                <span class="ds__nav__text">Logout</span>
              </a>
            </li>
            <li class="ds__nav__item">
              <a
                disabled
                href="javascript:void(0);"
                class="ds__nav__link ds__nav__link--white text-center"
              >
                <span>BaskytBusiness</span>v{{ $store.getters.appVersion }}
              </a>
            </li>
            <template v-if="deferredPrompt !== null">
              <li class="ds__nav__item">
                <a
                  :disabled="waiting"
                  href="javascript:void(0);"
                  @click="installApp"
                  class="ds__nav__link ds__nav__link--white"
                >
                  <div class="ds__nav__image__container">
                    <img
                      src="@/assets/static-assets/bottom-app-bar/help@3x.png"
                      alt="Install App"
                      class="ds__nav__image"
                    />
                  </div>
                  <span class="ds__nav__text">Install</span>
                </a>
              </li>
            </template>
          </ul>
        </b-row>
      </div>
      <div class="overlay-menu__back-btn" @click="closeOverlayMenu">
        <i class="fa fa-chevron-right"></i>
      </div>
      <div class="overlay-menu__socials">
        <div>
          <a :disabled="waiting" href="javascript:void(0)">
            <i class="fa fa-facebook"></i>
          </a>
          <a :disabled="waiting" href="javascript:void(0)">
            <i class="fa fa-globe"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { GET_INSTALL_STATUS } from "@/core/services/store/general.module";
import { LOGOUT } from "@/core/services/store/auth.module";
import { getUserName, getUserAvatar } from "@/core/services/jwt.service";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}mobile-second-menu`,
  props: {
    classToDisplay: String
  },
  data() {
    return {
      waiting: false,
      userName: getUserName(),
      avatar: getUserAvatar()
    };
  },
  methods: {
    ...mapGetters(["getInstallPrompt"]),
    installApp() {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === "accepted") {
          this.$store.dispatch(GET_INSTALL_STATUS, null);
        }
      });
    },
    closeOverlayMenu() {
      this.$emit("toggleOverlayMenu");
      return true;
    },
    async logout() {
      this.waiting = true;
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: "Login" });
      });
      this.waiting = false;
    }
  },
  computed: {
    ...mapState({
      deferredPrompt: state => state.general.installPrompt,
      region: state => {
        if (state.regions.region === "") {
          return "";
        }
        return state.regions.region ? "US" : "PK";
      }
    })
  }
};
</script>

<style lang="sccss" scoped></style>
