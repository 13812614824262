var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ds"},[(_vm.deferredPrompt !== null)?[_c('a',{staticClass:"ds__nav__link ds__install-button",attrs:{"disabled":_vm.waiting,"href":"javascript:void(0);"},on:{"click":_vm.installApp}},[_vm._m(0),_c('span',{staticClass:"ds__nav__text ds__nav__text--install"},[_vm._v("Install")])])]:_vm._e(),_c('a',{staticClass:"ds__user",staticStyle:{"pointer-events":"none"},attrs:{"disabled":_vm.waiting,"href":"javascript:void(0);"}},[_c('div',{staticClass:"ds__user__image"},[_c('img',{attrs:{"src":_vm.avatar !== false
            ? _vm.avatar
            : '@/assets/static-assets/defaults/user.jpg',"alt":"Default User"}})]),_c('span',{staticClass:"ds__user__text"},[_vm._v(_vm._s(_vm.userName !== "" ? _vm.userName : "Maroo Tandoor"))])]),_c('ul',{staticClass:"ds__nav"},[_c('li',{staticClass:"ds__nav__item"},[_c('router-link',{attrs:{"to":"/orders/pending"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"ds__nav__link",class:[
            _vm.$route.path.includes('/orders/') && 'ds__nav__link--active'
          ],attrs:{"disabled":_vm.waiting,"href":href},on:{"click":navigate}},[_c('div',{staticClass:"ds__nav__image__container"},[(_vm.$route.path.includes('/orders/'))?_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/Path 3959@3x.png"),"alt":_vm.$route.path.includes('/orders/') ? 'Orders Active' : 'Orders'}}):_vm._e(),(!_vm.$route.path.includes('/orders/'))?_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/Path 3959a@3x.png"),"alt":_vm.$route.path.includes('/orders/') ? 'Orders Active' : 'Orders'}}):_vm._e()]),_c('span',{staticClass:"ds__nav__text"},[_vm._v("Orders")])])]}}])})],1),_c('li',{staticClass:"ds__nav__item"},[_c('router-link',{attrs:{"to":"/order-status/in-progress"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"ds__nav__link",class:[
            _vm.$route.path.includes('/order-status/') && 'ds__nav__link--active'
          ],attrs:{"disabled":_vm.waiting,"href":href},on:{"click":navigate}},[_c('div',{staticClass:"ds__nav__image__container"},[(_vm.$route.path.includes('/order-status/'))?_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/ic_cached_24pxa@3x.png"),"alt":_vm.$route.path.includes('/order-status/')
                  ? 'Order-status-Active'
                  : 'Order-status'}}):_vm._e(),(!_vm.$route.path.includes('/order-status/'))?_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/ic_cached_24px@3x.png"),"alt":_vm.$route.path.includes('/order-status/')
                  ? 'Order-status-Active'
                  : 'Order-status'}}):_vm._e()]),_c('span',{staticClass:"ds__nav__text"},[_vm._v("Order Status")])])]}}])})],1),_c('li',{staticClass:"ds__nav__item"},[_c('router-link',{attrs:{"to":"/order-history"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"ds__nav__link",class:[
            isActive && 'ds__nav__link--active',
            isExactActive && 'ds__nav__link--active'
          ],attrs:{"disabled":_vm.waiting,"href":href},on:{"click":navigate}},[_c('div',{staticClass:"ds__nav__image__container"},[(_vm.$route.path.includes('/order-history'))?_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/historya@3x.png"),"alt":isActive && isExactActive
                  ? 'Order-History-active'
                  : 'Order-History'}}):_vm._e(),(!_vm.$route.path.includes('/order-history'))?_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/history@3x.png"),"alt":isActive && isExactActive
                  ? 'Order-History-active'
                  : 'Order-History'}}):_vm._e()]),_c('span',{staticClass:"ds__nav__text"},[_vm._v("Order History")])])]}}])})],1),(_vm.regionCheckUSorOther === false)?_c('li',{staticClass:"ds__nav__item"},[_c('router-link',{attrs:{"to":"/payments/pending"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"ds__nav__link",class:[
            _vm.$route.path.includes('/payments/') && 'ds__nav__link--active'
          ],attrs:{"disabled":_vm.waiting,"href":href},on:{"click":navigate}},[_c('div',{staticClass:"ds__nav__image__container"},[(_vm.$route.path.includes('/payments/'))?_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/edita@3x.png"),"alt":_vm.$route.path.includes('/payments/')
                  ? 'Payments Active'
                  : 'Payments'}}):_vm._e(),(!_vm.$route.path.includes('/payments/'))?_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/edit@3x.png"),"alt":_vm.$route.path.includes('/payments/')
                  ? 'Payments Active'
                  : 'Payments'}}):_vm._e()]),_c('span',{staticClass:"ds__nav__text"},[_vm._v("Payments")])])]}}],null,false,2345025821)})],1):_vm._e(),(_vm.regionCheckUSorOther === false)?_c('li',{staticClass:"ds__nav__item"},[_c('router-link',{attrs:{"to":"/invoices/allinvoice"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"ds__nav__link",class:[
            _vm.$route.path.includes('/invoices/') && 'ds__nav__link--active'
          ],attrs:{"disabled":_vm.waiting,"href":href},on:{"click":navigate}},[_c('div',{staticClass:"ds__nav__image__container"},[(_vm.$route.path.includes('/invoices/'))?_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/Path 396a9@3x.png"),"alt":_vm.$route.path.includes('/invoices/')
                  ? 'Invoices Active'
                  : 'Invoices'}}):_vm._e(),(!_vm.$route.path.includes('/invoices/'))?_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/Path 3969@3x.png"),"alt":_vm.$route.path.includes('/invoices/')
                  ? 'Invoices Active'
                  : 'Invoices'}}):_vm._e()]),_c('span',{staticClass:"ds__nav__text"},[_vm._v("Invoices")])])]}}],null,false,3872956861)})],1):_vm._e(),_c('li',{staticClass:"ds__nav__item"},[_c('router-link',{attrs:{"to":"/help"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{staticClass:"ds__nav__link",class:[
            isActive && 'ds__nav__link--active',
            isExactActive && 'ds__nav__link--active'
          ],attrs:{"disabled":_vm.waiting,"href":href},on:{"click":navigate}},[_c('div',{staticClass:"ds__nav__image__container"},[(_vm.$route.path.includes('/help'))?_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/ic_help_outaline_24px@3x.png"),"alt":isActive && isExactActive ? 'Help Active' : 'Help'}}):_vm._e(),(!_vm.$route.path.includes('/help'))?_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/ic_help_outline_24px@3x.png"),"alt":isActive && isExactActive ? 'Help Active' : 'Help'}}):_vm._e()]),_c('span',{staticClass:"ds__nav__text"},[_vm._v("Help")])])]}}])})],1),_c('li',{staticClass:"ds__nav__item"},[_c('a',{staticClass:"ds__nav__link",attrs:{"disabled":_vm.waiting,"href":"javascript:void(0);"},on:{"click":_vm.logout}},[_vm._m(1),_c('span',{staticClass:"ds__nav__text"},[_vm._v("Logout")])])]),_c('li',{staticClass:"ds__nav__item"},[_c('div',{staticClass:"app-version app-version--lg text-center"},[_c('span',[_vm._v("BaskytBusiness")]),_vm._v("v"+_vm._s(_vm.$store.getters.appVersion)+" ")])])])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ds__nav__image__container ds__nav__image__container--install"},[_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/bottom-app-bar/help-silver@3x.png"),"alt":"install pwa"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ds__nav__image__container"},[_c('img',{staticClass:"ds__nav__image",attrs:{"src":require("@/assets/static-assets/side bar/XMLID_2_@3x.png"),"alt":"install pwa"}})])
}]

export { render, staticRenderFns }