<template>
  <b-row class="bmn">
    <ul class="bmn__nav">
      <li class="bmn__nav__item">
        <router-link
          to="/performance"
          class="bmn__nav__link"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a :href="href" @click="navigate">
            <div class="bmn__nav__image__container">
              <img
                src="@/assets/static-assets/bottom-app-bar/performance@3x.png"
                :alt="
                  isActive && isExactActive
                    ? 'Performance Active'
                    : 'Performance'
                "
                class="bmn__nav__image"
              />
            </div>
            <span class="bmn__nav__text">Performance</span>
          </a>
        </router-link>
      </li>
      <li v-if="regionCheckUSorOther === false" class="bmn__nav__item">
        <router-link
          to="/payments/pending"
          class="bmn__nav__link"
          v-slot="{ href, navigate, isActive }"
        >
          <a :href="href" @click="navigate">
            <div class="bmn__nav__image__container">
              <img
                src="@/assets/static-assets/bottom-app-bar/payments@3x.png"
                :alt="isActive ? 'Payments Active' : 'Payments'"
                class="bmn__nav__image"
              />
            </div>
            <span class="bmn__nav__text">Payments</span>
          </a>
        </router-link>
      </li>
      <li class="bmn__nav__item">
        <router-link
          to="/orders/pending"
          class="bmn__nav__link bmn__nav__link--middle"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a :href="href" @click="navigate">
            <div class="bmn__nav__image__container">
              <img
                src="@/assets/static-assets/bottom-app-bar/baskyt@3x.png"
                :alt="isActive && isExactActive ? 'Orders Active' : 'Orders'"
                class="bmn__nav__image bmn__nav__image--middle"
              />
            </div>
          </a>
        </router-link>
      </li>
      <li class="bmn__nav__item">
        <router-link
          to="/invoices/allinvoice"
          class="bmn__nav__link"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a :href="href" @click="navigate">
            <div class="bmn__nav__image__container">
              <img
                src="@/assets/static-assets/bottom-app-bar/revenue@3x.png"
                :alt="isActive && isExactActive ? 'Revenue Active' : 'Revenue'"
                class="bmn__nav__image"
              />
            </div>
            <span class="bmn__nav__text">Invoices</span>
          </a>
        </router-link>
      </li>
      <li class="bmn__nav__item">
        <router-link
          to="/my-profile"
          class="bmn__nav__link"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <a :href="href" @click="navigate">
            <div class="bmn__nav__image__container">
              <img
                src="@/assets/static-assets/bottom-app-bar/my-profile@3x.png"
                :alt="
                  isActive && isExactActive ? 'My Profile Active' : 'My Profile'
                "
                class="bmn__nav__image"
              />
            </div>
            <span class="bmn__nav__text">My Profile</span>
          </a>
        </router-link>
      </li>
    </ul>
  </b-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}bottom-navbar`,
  data() {
    return {};
  },
  computed: {
    ...mapState({
      regionCheckUSorOther: state => state.regions.region
    })
  }
};
</script>
